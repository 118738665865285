import React from 'react';
import { ParallaxMordo, Mordo } from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import { useStaticQuery, graphql } from 'gatsby';
import Video from 'src/assets/videos/chapter_4/4-4-1-bg.webm';
import VideoMP4 from 'src/assets/videos/chapter_4/4-4-1-bg.mp4';
import BackgroundAnimation from 'src/components/desktop/backgroundAnimation/backgroundAnimation';
import LeftColContent from 'src/components/desktop/leftColContent/leftColContent';
import HyperLink from 'src/components/_shared/hyperlink/hyperlink';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import { getLangFromPath } from 'src/utils/index.js';
import { SlideLarge } from '../../_styles';
import AudioPlayer from '../../../../../components/desktop/audioPlayer/audioPlayer';
import { mediaServer } from '../../../../../../globals';

const Slide = ({ query }) => {
  const assetQuery = useStaticQuery(graphql`
  query {
    mainscene: file(relativePath: { eq: "chapter_3/sub_2/3-2-2-bg.png"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  scenelayer1: file(relativePath: { eq: "chapter_4/sub_4/4-4-1-parallax.png"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  audio1: file(relativePath: { eq: "chapter_4/sub_4/4-4-1-audio1.png"}) {
      childImageSharp {
          fluid(maxWidth: 275) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  audio2: file(relativePath: { eq: "chapter_4/sub_4/4-4-1-audio2.png"}) {
      childImageSharp {
          fluid(maxWidth: 275) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  }
  `);
  const ModalContents = () => (
    <MDXProvider>
      <MDXRenderer>{query.modal.body}</MDXRenderer>
    </MDXProvider>

  );

  const language = getLangFromPath();

  return (
    <SlideLarge>
      <ParallaxMordo column>
        <Mordo
          mainscene
          move={{ x: -20, y: -10 }}
          background={{ color: 'rgba(0,0,0,1)' }}
        />

        <Mordo
          scenelayer
          scene={2}
          move={{ x: 10, y: 20 }}
          background={{ image: assetQuery.scenelayer1.childImageSharp.fluid }}
          opacity={1}
        />
        <Mordo
          scenelayer
          scene={1}
          move={{ x: 10, y: 20 }}
          opacity={1}
        >
          <BackgroundAnimation src={Video} mp4={VideoMP4} responsive />
        </Mordo>
        <Mordo
          // tutaj musiałem dodać tego propsa zeby wypozycjonować LeftColContent bo jest w nim ustawiona wysokość na 100%, zmieniając procenty powinno się ustawić na takiej wysokości jakiej byś chciał
          flexi={{ flex: '0 0 70%' }}
          scene={2}
          move={{ x: -5, y: -5 }}
        >
          <LeftColContent body={query.leftColumn.body} />

        </Mordo>
        <Mordo
          flexi={{ alignItems: 'flex-start' }}
          // lub mozna tak poeksperymentować
          // flexi={{ column: true, alignSelf: 'flex-start', alignItems: 'center' }}
          move={{ x: 0, y: 0 }}
          scene={3}
        >
          <AudioPlayer audio={[`${mediaServer}/webm/${language.toLowerCase()}/441lisiewicz.webm`, `${mediaServer}/mp4/${language.toLowerCase()}/441lisiewicz.mp4`]} audioImage={assetQuery.audio1.childImageSharp.fluid} size={1} style={{ marginTop: '25vh', width: '30%' }} audioDescription={query.audio1.excerpt} />
          <AudioPlayer audio={[`${mediaServer}/webm/${language.toLowerCase()}/441szymanski.webm`, `${mediaServer}/mp4/${language.toLowerCase()}/441szymanski.mp4`]} audioImage={assetQuery.audio2.childImageSharp.fluid} size={1} style={{ marginTop: '25vh', width: '30%' }} audioDescription={query.audio2.excerpt} />

        </Mordo>
        <div css={{
          position: 'absolute',
          top: '47vh',
          left: '35vw',
          zIndex: 50,
        }}
        >
          <HyperLink body={ModalContents()} />
        </div>
      </ParallaxMordo>
    </SlideLarge>

  );
};


export default Slide;
