import React from 'react';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';

import Slide1 from 'src/slides/desktop/hu/chapter_4/sub_4/slide1';
import Slide2 from 'src/slides/desktop/hu/chapter_4/sub_4/slide2';
import WrapperMobile from 'src/slides/mobile/hu/chapter_4/sub_4/wrapperMobile';
import { isMobile } from 'react-device-detect';
import SEO from 'src/components/_shared/seo';


const allSlides = [<Slide1 />, <Slide2 />];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO title="Vörös terror lengyel földön | A varsói csata" lang="hu" description="A Vörös Hadsereg gyilkosságai, zsákmányszerzései és erőszakoskodásai 1920-ban." />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

const Mobile = () => (
  <>
    <SEO title="Vörös terror lengyel földön | A varsói csata" lang="hu" description="A Vörös Hadsereg gyilkosságai, zsákmányszerzései és erőszakoskodásai 1920-ban." />
    <WrapperMobile />
  </>
);

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
