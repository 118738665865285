import React from 'react';
import BackgroundPhoto from 'src/components/mobile/backgroundPhoto/backgroundPhoto';
import Quote from '../../../../../components/mobile/quote/quote';
import { BlockContainer } from '../../_styles';

const Slide = ({ query }) => (
  <BlockContainer height="1180px">
    <BackgroundPhoto fixed={query.file.childImageSharp.fixed} alt="slide-photo">
      <Quote fontColor="white" quoteText={query.allMdx.edges[0].node.body} />
    </BackgroundPhoto>
  </BlockContainer>
);

export default Slide;
