import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/desktop/_templates/chapter_4/sub_4/slide1';

const Slide = () => {
  const query = useStaticQuery(graphql`
  query {
    leftColumn: mdx(frontmatter: {language: {eq: "HU"}, title: {eq: "slide-4-4-1"}}) {
        body
      },
      audio1: mdx(frontmatter: {language: {eq: "HU"}, title: {eq: "slide-4-4-1-audio1"}}) {
        excerpt(pruneLength: 100000)
      },
      audio2: mdx(frontmatter: {language: {eq: "HU"}, title: {eq: "slide-4-4-1-audio2"}}) {
        excerpt(pruneLength: 100000)
      },
      modal: mdx(frontmatter: {language: {eq: "HU"}, title: {eq: "slide-4-4-1-modal"}}) {
        body
      },
}
  `);
  return (
    <Template query={query} />
  );
};


export default Slide;
