import React from 'react';
import { ParallaxMordo, Mordo } from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import { graphql, useStaticQuery } from 'gatsby';
import Quote from 'src/components/desktop/quote/quote';
import { SlideLarge } from '../../_styles';


const Slide = ({ query }) => {
  const assetQuery = useStaticQuery(graphql`
    query {
      bg: file(relativePath: { eq: "chapter_4/sub_4/4-4-2-bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <SlideLarge>
      <ParallaxMordo>
        <Mordo
          mainscene
          move={{ x: -20, y: -10 }}
          background={{ image: assetQuery.bg.childImageSharp.fluid }}
        />
        <Mordo
          flexi
          scene={2}
          move={{ x: 10, y: 20 }}
        >
          <div css={{
            alignSelf: 'center', width: '100%', margin: '10% auto 0',
          }}
          >
            <Quote isSmaller fontColor="white" quoteText={query.allMdx.edges[0].node.body} quoteAuthor={query.allMdx.edges[0].node.frontmatter.author} />

          </div>
        </Mordo>
      </ParallaxMordo>
    </SlideLarge>

  );
};

export default Slide;
