import React from 'react';
import BackgroundPhoto from 'src/components/mobile/backgroundPhoto/backgroundPhoto';
import TextAnimation from 'src/components/mobile/textAnimation/textAnimation';
import AudioPlayer from 'src/components/desktop/audioPlayer/audioPlayer';
import { getLangFromPath } from 'src/utils';
import { BlockContainer, ChpaterTitle } from '../../_styles';
import { mediaServer } from '../../../../../../globals';

const Slide = ({ query, title, audio1 = 'Mieczysław Lisiewicz', audio2 = 'Antoni Szymański' }) => (
  <BlockContainer height="1800px">
    <BackgroundPhoto fixed={query.file.childImageSharp.fixed} alt="slide-photo">
      <ChpaterTitle marginTop="8rem">{title}</ChpaterTitle>
      <TextAnimation
        containerStyle={{ height: '20%', marginTop: '1rem' }}
        body={query.mdx.body}
      />
      <AudioPlayer
        audio={[
          `${mediaServer}/webm/${getLangFromPath().toLowerCase()}/441lisiewicz.webm`,
          `${mediaServer}/mp4/${getLangFromPath().toLowerCase()}/441lisiewicz.mp4`,
        ]}
        audioImage={query.audioImageA.childImageSharp.fluid}
        audioDescription={audio1}
        size={2}
        style={{ margin: '10rem 0' }}
      />
      <AudioPlayer
        audio={[
          `${mediaServer}/webm/${getLangFromPath().toLowerCase()}/441szymanski.webm`,
          `${mediaServer}/mp4/${getLangFromPath().toLowerCase()}/441szymanski.mp4`,
        ]}
        audioImage={query.audioImageB.childImageSharp.fluid}
        audioDescription={audio2}
        size={2}
        style={{ margin: '10rem 0' }}
      />
    </BackgroundPhoto>
  </BlockContainer>
);

export default Slide;
